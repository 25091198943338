
import React from "react"

function Main() {
    return (
        <main>
            <div class="center-box">
                <div class="logo jumbo">amaruta<span class="tech">.tech</span></div>
                <p>Site under construction. Get in <b><a href="mailto:contact@amaruta.tech">contact here</a>.</b></p>
            </div>
            
        </main>
        
  );
}

export default Main;
